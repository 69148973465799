import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AwsService, CreditosService, DocumentosService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-revision-documentos',
  templateUrl: './revision-documentos.component.html',
  styleUrls: ['./revision-documentos.component.css']
})
export class RevisionDocumentosComponent implements OnInit {

  usuarios: any[] = [];
  usuariosPendientes: any[] = [];
  cols: any[];
  nombre = '';
  terminado = false;
  showmodalds = false;
  documentos = [];
  uid = '';
  us: any[];

  constructor( private usuariosservice: UsuarioService,
                private awsService: AwsService,
                private documentosService: DocumentosService,
                private creditosService: CreditosService,
                public router: Router ) {
  }

  ngOnInit() {
    this.uid = localStorage.getItem('uid');
    var boton = (document.getElementById('reset') as HTMLButtonElement)
    
    this.documentosService.getDoctoresPendientes(this.uid).subscribe(resp => {
      this.us = resp;
      this.filtrarUsuarios('p');
    });

    this.cols = [

      { field: 'nombre_doctor', header: 'Nombre' },
      { field: 'mail_doctor', header: 'Correo' },
      { field: 'documents', header: 'Documentos' },
      { field: 'customer', header: 'Afiliación' },
  ];

  }

  borrarUsuario(id){
    this.usuariosservice.borrarUsuario(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Usuario eliminado correctamente', 'success');
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

  showmodal(docs){
    this.showmodalds = true; 
    this.documentos = docs;
  }

  filtrarUsuarios(status){
    switch(status){
      case 'p':
        this.usuarios = this.us.filter(u => u.documentos_pi > 0 || !u.id_customer);
        break;
      case 'c':
        this.usuarios = this.us.filter(u => u.documentos_pi == 0 && u.id_customer);
      break;
      }
    setTimeout(() => {
      document.getElementById('reset').click();
    }, 1800);
  }

  redirigir(id){
    this.creditosService.getUserCotizaciones(id).subscribe(r => {
      if(Object.keys(r).length > 0){
        swal2.fire('Atención', 'No se puede editar el registro porque ya existen cotizaciones previas')
      }else{
        this.router.navigate([`/registroemp/${id}`]);
      }
    })
  }

   
  

}

