import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AwsService } from '../../services/awsser/aws.service';
import swal2 from 'sweetalert2';
import { Observable, Subject } from 'rxjs';
import { ContribuyentesService, CreditosService, UsuarioService } from 'src/app/services/service.index';
import {MatAccordion} from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ListasService } from '../../services/listas/listas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registroempleado',
  templateUrl: './registroempleado.component.html',
  styleUrls: ['./registroempleado.component.css']
})
export class RegistroempleadoComponent implements OnInit {
   @ViewChild(MatAccordion) accordion: MatAccordion;
   // Para el control del formulario //
   showModalTerminosYCondiciones = false;
   exppanel = false;
   disablepanel = true;
   idu: string;
  // Para el control de los archivos a subir //  
  archivoNombres = []
  images = [];
  files = [];
  filesNames = [];
  lists = [];
  periodicidadPago = [];
  capturaCp;
  showModalNip = false;

   myForm = new FormGroup({
    filesIngresos: new FormControl(),
    fileIne: new FormControl(),
    fileCompDom: new FormControl(),
    fileCurp: new FormControl(),
    fileCsf: new FormControl(),
    fileXml: new FormControl(),
    fileEcuenta: new FormControl()
  });
  imagen = '';
  empresa = false;
  houseType = [];
  bancos: any[];
  esj = false;

  // ------------------
  documentos = [];
  cargado = false;
  archivoDocs = [];
  resul = {};
  legal_entity = new FormGroup({
    fiscal_regime: new FormControl(),
    rfc: new FormControl(),
    rug: new FormControl(),
    business_name: new FormControl(),
    phone: new FormControl(),
    mobile: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    business_email: new FormControl('', [Validators.required, Validators.email]),
    main_activity: new FormControl(),
    // fiel: new FormControl(),
  })

  person = new FormGroup({
    fiscal_regime: new FormControl(), 
    rfc: new FormControl(), 
    curp: new FormControl(), 
    imss: new FormControl(),   
    first_name: new FormControl(), 
    last_name: new FormControl(), 
    second_last_name: new FormControl(), 
    gender: new FormControl(),    
    nationality: new FormControl(), 
    birth_country: new FormControl(), 
    birthplace: new FormControl(), 
    birthdate: new FormControl(),  
    id_type: new FormControl(), 
    identification: new FormControl(), 
    phone: new FormControl(),
    mobile: new FormControl(), 
    email: new FormControl('', [Validators.required, Validators.email]),
  })
  
  contributor_address = new FormGroup({
    municipality_id: new FormControl(),
    state_id: new FormControl(),
    address_type: new FormControl(),
    street: new FormControl(),
    external_number: new FormControl(),
    apartment_number: new FormControl(),
    postal_code: new FormControl(),
    address_reference: new FormControl(),
    suburb: new FormControl(),
  })
  
  
  contributor = new FormGroup({
    contributor_type: new FormControl(),
    bank: new FormControl(),
    account_number: new FormControl(),
    clabe: new FormControl(),
    extra1 :new FormControl(),
  })

  doctor = new FormGroup({
    cedula: new FormControl( null),
    speciality: new FormControl( null),
    seniority: new FormControl( null),
    own_consultory: new FormControl( null),
    fee: new FormControl( null),
    average_fee: new FormControl( null),
    surgery_income: new FormControl( null),
    monthly_income: new FormControl( null),
    month_patients: new FormControl( null),
    extra_incomes: new FormControl( null),
    detail_extra_incomes: new FormControl( null),
    payment_rent: new FormControl( null),
  });

  legal_entity_group = this._formBuilder.group({});
  doctor_group = this._formBuilder.group({});
  contributor_address_group = this._formBuilder.group({});
  contributor_group = this._formBuilder.group({});
  fiscal_regime: any[];
  address_type: any[];
  suburb_type: any[];
  martial_status: any[];
  seniority: any[];
  company_rate: any[];
  weekday: any[];
  bank: any[];
  countries: any[];
  states: any[];
  municipies: any[];
  colonias: any[];
  fiscal: any;
  infoDoctor: any;
  vendedor = false;
  customerExists = false;
  customer: any;
  nombre_cliente = '';
  correo_cliente = '';
  telefono_cliente = '';
  nombre_vendedor = '';
  correo_vendedor = '';
  telefono_vendedor = '';
  afiliacionInfo: any;
  pais: any;
  mostrarBoton = false;
  usuarioInfo = {name: '', email: ''};

  constructor(private cont: ContribuyentesService,
              private listasService: ListasService,
              private router: Router,
              private aws: AwsService,
              private _formBuilder: FormBuilder,
              private usuarioService: UsuarioService,
              private creditosService: CreditosService,
              private route: ActivatedRoute) {
               
  }

  ngOnInit(): void {
    this.lists = [];
    this.exppanel = true;
    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
    this.fiscal_regime = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
    this.address_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
    this.suburb_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
    this.martial_status = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
    this.houseType = this.lists.filter(p => p.domain === 'HOUSE_TYPE');
    this.seniority = this.lists.filter(p => p.domain === 'SENIORITY');
    this.company_rate = this.lists.filter(p => p.domain === 'COMPANY_RATE');
    this.weekday = this.lists.filter(p => p.domain === 'WEEKDAY');
    this.bank = this.lists.filter(p => p.domain === 'PERSON_BANK');
    })
    this.aws.getCountriesD().subscribe( resp => { this.countries = resp; } );

    if(!this.route.snapshot.paramMap.get('idDoctor')){
      this.idu = localStorage.getItem('uid')
    }else{
      this.idu = this.route.snapshot.paramMap.get('idDoctor');
      this.vendedor = true;
    }

   /* if (localStorage.getItem('uid')) {
   } else {
      this.idu = 'db9a7967-723b-4ac1-b684-d6a217fc39a2';
   } */

   this.archivoNombres = [];
   if(!this.vendedor){
     this.usuarioService.getUserDocumentsEndpoint(this.idu).subscribe(resp => {
       this.documentos = resp;
       this.archivoDocs = [];
       /* resp['data'].forEach(d => {
         var paso = d.attributes;
         this.documentos.push(paso);
       }); */
       this.documentos.forEach(d => {
         if(d.status == 'IN' && d.url){
           this.archivoDocs.push(d.name)
           this.archivoNombres[d.name] = d.url;
         }
         // if(this.archivoDocs.length == this.documentos.length){
         this.documentosCompletos();
         // }
   
       })
       this.cargado = true;
     })
    }else{
     this.usuarioService.getUserDocuments(this.idu).subscribe(resp => {
      //  this.documentos = resp;
      this.documentos = [];
       this.archivoDocs = [];
       resp['data'].forEach(d => {
         var paso = d.attributes;
         this.documentos.push(paso);
       });
       this.documentos.forEach(d => {
         if(d.status == 'IN' && d.url){
           this.archivoDocs.push(d.name)
           this.archivoNombres[d.name] = d.url;
         }
         // if(this.archivoDocs.length == this.documentos.length){
         this.documentosCompletos();
         // }
   
       })
       this.cargado = true;
     })

   }


  this.usuarioService.getusuarioById(this.idu).subscribe(resp => {
    this.usuarioInfo = resp['data']['attributes']
    if(resp['data'].relations.customers.length > 0){
      this.customerExists = true;
      this.customer = resp['data'].relations.customers[0].attributes;
      if(this.vendedor){
        this.usuarioService.getInfoById(this.idu).subscribe(resp => {
          this.afiliacionInfo = resp[0];
          this.getColonias(this.afiliacionInfo['customers'][0]['contributor']['contributor_addresses'][0]['postal_code']);
          this.buscaEdo(this.afiliacionInfo['customers'][0]['contributor']['contributor_addresses'][0]['state']['country_id'])
          this.pais = this.afiliacionInfo['customers'][0]['contributor']['contributor_addresses'][0]['state']['country_id'];
          this.buscaMun(this.afiliacionInfo['customers'][0]['contributor']['contributor_addresses'][0]['state_id'])
          console.log('de aqui es la info para autocompletar', this.afiliacionInfo)
          this.fiscal = this.afiliacionInfo['customers'][0].customer_type;
          if(this.afiliacionInfo['customers'][0].customer_type == 'PF'){
            Object.keys(this.person.value).forEach(k => {
              this.person.patchValue({[k]: this.afiliacionInfo['customers'][0]['contributor']['person'][k]})
            });
          }else if(this.afiliacionInfo['customers'][0].customer_type == 'PM'){
            Object.keys(this.legal_entity.value).forEach(k => {
              this.legal_entity.patchValue({[k]: this.afiliacionInfo['customers'][0]['contributor']['legal_entity'][k]})
            });
          }
          Object.keys(this.contributor_address.value).forEach(k => {
            this.contributor_address.patchValue({[k]: this.afiliacionInfo['customers'][0]['contributor']['contributor_addresses'][0][k]})
          });
          Object.keys(this.contributor.value).forEach(k => {
            this.contributor.patchValue({[k]: this.afiliacionInfo['customers'][0]['contributor'][k]})
          });
        })
      }
    }
  })

  this.usuarioService.getUserDoctor(this.idu).subscribe(resp => {
    this.infoDoctor = resp[0];
    if(this.infoDoctor == undefined){return;}
    Object.keys(this.doctor.value).forEach(k => {
      this.doctor.patchValue({[k]: this.infoDoctor[k]})
    });
    /* this.fiscal = this.infoDoctor.con_type;
    if(this.infoDoctor.con_type == 'PF'){
      this.person.patchValue({
        rfc: this.infoDoctor.rfc,
        email: this.infoDoctor.user.email
      })
    }else if(this.infoDoctor.con_type == 'PM'){
      this.legal_entity.patchValue({
        rfc: this.infoDoctor.rfc,
        email: this.infoDoctor.user.email
      })
    } */
  })

  }

  mostrarNip(){
    this.showModalNip = true;
  }

  mostrarTerminos(){
    this.showModalTerminosYCondiciones = true;
  }

  buscaEdo(id) {
    this.aws.getStatesId(id).subscribe( r => {this.states = r;} )
   }
 
   buscaMun(id) {
    this.aws.getMunicipios(id).subscribe( r => {this.municipies = r;} )
   }

   getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {
       this.colonias = r;
       this.capturaCp = false;
       if(this.colonias.length == 0){
         this.capturaCp = true;
       }
     })
   }
  
// EL DE BENJA
  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 3px #73CB38";
  }
  dragLeave(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  

  async fileBrowseHandler( e: any, doc: string, idd ) {
    await this.callupload(e[0], this.idu, doc, idd);
  }


 borradocatta(d, n, idd){
  this.myForm.value[d] = null;
  delete this.archivoNombres[n];
  delete this.archivoDocs[n];
  var params = {};
  var user_document = {
    url: '',
    status: 'PI'
  };
  params['user_document'] = user_document;
  this.usuarioService.setUserDocuments(this.idu, idd, params).subscribe(resp=> {
    this.documentos = [];
    this.ngOnInit();
  }, err => {
    console.log('error', err)
  })
 }
 
  documentosCompletos(){
    if(this.archivoDocs.length == this.documentos.length){
      // this.router.navigate([`/maincliente/solicitudcredito/${this.idu}`])
      //this.expandPanel();
      this.exppanel = true;
    }else if(this.archivoDocs.length == this.documentos.length-1 && this.archivoDocs.filter(a => a == "Acta de matrimonio").length == 0){
      //this.expandPanel();
      this.exppanel = true;
    }else{
      this.mostrarBoton = false;
    }
  }

  expandPanel(){
    this.mostrarBoton = true;
    Swal.fire({
      title: 'Documentos completos',
      html: `¿Los documentos cargados son los correctos?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Es correcto',
      cancelButtonText: 'Hay un error'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.router.navigate([`/registroemp`])
        this.exppanel = true;
      }
    })
  }
  
  validarTerminos(){
     this.showModalTerminosYCondiciones = false;
     this.showModalNip = true;
  }

  validarNip(){
    /* alert("revisar porque originalmente se mandaba el customer, tambien donde se recupera el nip");
    this.showModalNip = false;
    this.registrar(); */
     // VALIDA EL NIP POR PRIMERA VEZ Y MUESTRA 
    var nip = (document.getElementById('nip') as HTMLInputElement).value;
    // ENVIA EL CUSTOMER ID
    this.usuarioService.getNipStatus(nip,this.idu).subscribe( resp => {
      if ( resp.status !== true ) {
        swal2.fire(
        'El NIP es incorrecto',
        'Intente de nuevo',
        'error'
        
     ).then(okay => {
      // if (okay) {
        swal2.close();
      //  this.focusNip();
    //  }
   });
      this.showModalNip = true;
      // this.nipValidado = false;
      (document.getElementById('nip') as HTMLInputElement).value = ''

    } else {
      swal2.fire('Éxito', 'NIP validado correctamente', 'success');
          this.showModalNip = false;
          this.registrar();
          }
      }
      );  
  }

  // para validar y limitar el número de decimales en tiempo real
  validateInput(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    const parts = value.split('.');
    if (parts.length === 2) {
      parts[1] = parts[1].substring(0, 2);
      value = parts.join('.');
    }

    input.value = value;
  }

  // Para cuando pierda el foco formatear
  fmCurrency(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // elimino caracteres no numericos excepto el punto decimal
    value = value.replace(/[^0-9.]/g, '');

    // mantengo solo el primer decimal
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    // formateo y limito a solo dos decimales
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    decimalPart = (decimalPart || '').padEnd(2, '0').substring(0, 2);

    // actualizo visualmente
    input.value = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
  }

  registrar(){
    var params = { 
      // quote_id: this.customer_credit.quote_id,
      // customer_credit_id: this.customer_credit.id
    }

    if(!customer){
      this.contributor.value.contributor_type = this.fiscal;
      params['contributor'] = this.contributor.value;

      params['contributor_address'] = this.contributor_address.value;

      params['doctor'] = this.doctor.value;

      if(this.fiscal == 'PF'){
        this.person.value.fiscal_regime = this.fiscal;
        params['person'] = this.person.value;
      }else if(this.fiscal == 'PM'){
        this.legal_entity.value.fiscal_regime = this.fiscal;
        params['legal_entity'] = this.legal_entity.value;
      }

      var customer_reference = this.nombre_cliente + ' ' + this.correo_cliente + ' ' + this.telefono_cliente;
      var vendor_reference = this.nombre_vendedor + ' ' + this.correo_vendedor + ' ' + this.telefono_vendedor;

      var customer = { 
        customer_type: this.fiscal,
        status: "AC",
        customer_reference: customer_reference,
        vendor_reference: vendor_reference,
        user_id: this.idu //usuario firmado
      }
      params['customer'] = customer;
    }else{
      var cus = {
        user_id: this.idu
      }
      params['customer'] = cus
    }

    
    if(!this.vendedor){
      console.log(params);
      this.registro(params);
    }else if(this.vendedor){
      params['contributor_id'] = this.afiliacionInfo.customers[0].contributor.id;
      params['customer_id'] = this.afiliacionInfo.customers[0].id;
      if(this.fiscal == 'PF'){
        params['person_id'] = this.afiliacionInfo.customers[0].contributor.person.id;
      }else if(this.fiscal == 'PM'){
        params['legal_entity_id'] = this.afiliacionInfo.customers[0].contributor.legal_entity.id;
      }

      delete params['contributor_address'];
      console.log(params);
      this.actualizacion(params);
    }
    
  }

  actualizacion(params){
    this.creditosService.actualizarDoctor(params).subscribe(resp => {
      console.log(resp)
      Swal.fire({
        title: 'Éxito',
        html: `Registro actualizado correctamente.`,
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          if(!this.vendedor){
            this.router.navigate([`/maincliente/solicitudcredito/${this.idu}`])
          }else{
            this.router.navigate([`/panelcontrol/revisionDocumentos`])
          }
        }
      })
    }, err => {
      console.log(err);
      swal2.fire('Atención', 'Algo salió mal', 'info');
    })
  }

  registro(params){
    this.creditosService.registroDoctor(params).subscribe(resp => {
      console.log(resp)
      Swal.fire({
        title: 'Éxito',
        html: `Registro correcto.`,
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          if(!this.vendedor){
            this.router.navigate([`/maincliente/solicitudcredito/${this.idu}`])
          }else{
            this.router.navigate([`/panelcontrol/revisionDocumentos`])
          }
        }
      })
    }, err => {
      console.log(err);
      try{
        var error = err.error.error;
        var mensaje = '';
        Object.keys(error).forEach(e => {
          mensaje += e + ': ' + error[e] + '\n';
        })
        swal2.fire('Atención', mensaje, 'info');
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
  }

  resetNip(){
    // SERVICIO PARA GENERAR NUEVO NIP
    this.usuarioService.resetNip(this.idu).subscribe( resp => {
      console.log(resp);
      if ( resp.status !== true ) {
        swal2.fire(
        'Ha ocurrido un error',
        'Vuelva a intentarlo',
        'error'
     );
    } else {
      swal2.fire('Se le ha enviado un nuevo NIP', 'Revise su correo', 'success');
          }
      }, err => {
        console.log(err);
      }
      );  
  }

  async callupload(file, idu, doc, idd) {
    try {
      const r = await this.aws.uploadFile(file, idu)
      var params = {};
      var user_document = {
        url: r.Location,
        status: 'IN',
      };
      params['user_document'] = user_document;
      this.usuarioService.setUserDocuments(idu, idd, params).subscribe(resp=> {
        console.log(resp)
        this.archivoNombres[doc] = r.Location;
        this.archivoDocs.push(doc)
        // if(this.archivoDocs.length == this.documentos.length){
          this.documentosCompletos();
          this.ngOnInit();
      }, err => {
        console.log('error', err);
        try{
          swal2.fire('Atención', err.error.errors[0], 'info');
        }catch{
          swal2.fire('Atención', 'Algo salió mal', 'info');
        }
      })
      // }
      return r.Location
    } catch (error) {
      console.log('error', error);
      let resp = {
        status: false,
        resp: error,
        msg: 'Fallo al subir archivo'
      }
      swal2.fire('Error', 'Ocurrió algo en la carga', 'info')
      return resp
    }
  }


}

